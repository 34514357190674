/** 注册 */
<template>
  <section class="account-form">
    <el-tabs class="account-form-header" v-model="activeName">
      <el-tab-pane label="新用户注册" name="first"></el-tab-pane>
    </el-tabs>
    <el-form :model="form" :rules="$rules.common" ref="form">

      <el-form-item prop="name">
        <el-input v-model="form.name" placeholder="请输入用户名称" prefix-icon="el-icon-user"> </el-input>
      </el-form-item>

      <el-form-item prop="phone">
        <el-input v-model="form.phone" placeholder="请输入手机号码" prefix-icon="el-icon-mobile-phone"></el-input>
      </el-form-item>

      <el-form-item prop="captcha">
        <el-input class="flex" v-model="form.captcha" placeholder="请输入验证码" prefix-icon="el-icon-message">
          <el-button class="send-code" type="primary" slot="append" :disabled="isDisabled" @click="getCode('form')">
            {{codeMsg}}
          </el-button>
        </el-input>
      </el-form-item>

      <el-form-item prop="password">
        <el-input type="password" v-model="form.password" placeholder="请输入登录密码" autocomplete="off"
          prefix-icon="el-icon-lock"></el-input>
      </el-form-item>

      <el-form-item prop="password_confirmation" :rules="[{ validator: validatePasswords, trigger: 'blur' }]">
        <el-input type="password" v-model="form.password_confirmation" placeholder="请确认登录密码" autocomplete="off"
          prefix-icon="el-icon-lock">
        </el-input>
      </el-form-item>

      <el-form-item class="form-btns">
        <el-button class="theme-back" type="primary" :loading="isLoading" @click="submit('form')">
          注 册</el-button>
      </el-form-item>
    </el-form>
    <div class="account-form-footer">
      <router-link to="/account/login" replace>已有账号，立即登录</router-link>
    </div>
  </section>
</template>
<script>
import { sendcaptcha, register } from '@/api/account'
import { getItem } from '@/utils/storage'

export default {
  name: 'Register',
  data () {
    return {
      activeName: 'first',
      form: {
        name: '',
        phone: '',
        captcha: '',
        password: '',
        password_confirmation: '',
      },
      isLoading: false,
      isDisabled: false,
      codeMsg: '获取验证码',
      // 二次密码验证
      validatePasswords: (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.form.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      },
    };
  },
  watch: {
    $route (newVal) {
      this.endTimer()
    }
  },
  methods: {
    // 注册
    async submit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.isLoading = true
          try {
            let { data, code, msg } = await register(this.form)
            if (code == 200) {
              this.$message.success(msg);
              this.$store.dispatch('setUserToken', data.token)
              this.$store.dispatch('setUserInfo', data.userInfo)
              let redirect = this.$route.query.redirect
              if (redirect) { //如果存在参数
                this.$router.replace(redirect)//则跳转至进入登录页前的路由
              } else {
                this.$router.replace(getItem('router_url', 'sessionStorage') || '/')
              }
              this.$refs.form.resetFields()

            } else {
              this.$message.error(msg)
            }
          } catch (error) { }
          this.endTimer()
          this.isLoading = false
        } else {
          return false;
        }
      });
    },
    //  获取验证码
    async getCode (formName) {
      const validateList = []   // 定义空数组
      this.$refs[formName].validateField(["phone"], valid => {
        // this.field  为要校验的部分rules数组
        validateList.push(valid)
      })

      if (validateList.every((item) => item === '')) {
        this.codeMsg = '加载中'
        this.countDown(60)
        try {
          let { code, msg } = await sendcaptcha(this.form.phone, 'register')
          if (code == 200) {
            this.$message.success(msg);
          } else {
            this.$message.error(msg);
            this.endTimer()
          }
        } catch (error) {
          this.$message.error(error);
          this.endTimer()
        }

      }
    },
    // 开始倒计时
    countDown (num) {
      if (!this.timer) {
        this.count = num
        this.codeMsg = `${num}秒`
        this.isDisabled = true
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= num) {
            this.count--
            this.codeMsg = `${this.count}秒`
          } else {
            this.endTimer()
          }
        }, 1000)
      }
    },
    // 结束倒计时
    endTimer (msg) {
      this.isDisabled = false
      clearInterval(this.timer)
      this.timer = null
      this.codeMsg = msg || '重新发送'
    }
  },
  beforeDestroy () {
    this.endTimer()
  },
}
</script>
<style lang="scss" scoped>
/deep/ .account-form-header {
  .el-tabs__nav-scroll {
    display: flex;
    justify-content: center;
  }
}
</style>